.sku-xcc {
    background-color: rgba(244, 67, 54, 0.35) !important;
}

.sku-xca {
    background-color: rgba(255, 152, 0, 0.35) !important;
}

.sku-xwc {
    background-color: rgba(233, 30, 99, 0.35) !important;
}

.sku-xcm {
    background-color: rgba(253, 216, 53, 0.35) !important;
}

.sku-xcx {
    background-color: rgba(139, 195, 74, 0.35) !important;
}

.sku-xlm, .sku-xcs {
    background-color: rgba(94, 53, 177, 0.35) !important;
}

.sku-xgf {
    background-color: rgba(30, 136, 229, 0.35) !important;
}

.sku-xgb, .sku-xgs {
    background-color: rgba(38, 166, 154, 0.35) !important;
}

.sku-cell-xcc {
    background-color: rgba(244, 67, 54, 0.25) !important;
}

.sku-cell-xca {
    background-color: rgba(255, 152, 0, 0.25) !important;
}

.sku-cell-xwc {
    background-color: rgba(233, 30, 99, 0.25) !important;
}

.sku-cell-xcm {
    background-color: rgba(253, 216, 53, 0.25) !important;
}

.sku-cell-xcx {
    background-color: rgba(139, 195, 74, 0.25) !important;
}

.sku-cell-xlm, .sku-cell-xcs {
    background-color: rgba(94, 53, 177, 0.25) !important;
}

.sku-cell-xgf {
    background-color: rgba(30, 136, 229, 0.25) !important;
}

.sku-cell-xgb, .sku-cell-xgs {
    background-color: rgba(38, 166, 154, 0.25) !important;
}

.sku-dead {
    background: repeating-linear-gradient(-45deg, white, white 10px, #eee 10px, #eee 20px);
}

.sku-cell-dead {
    background: repeating-linear-gradient(-45deg, white, white 10px, #eee 10px, #eee 20px);
}
